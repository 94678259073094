<template>
  <div>
    <div ref="slaWrapper">
      <div style="text-align: justify; padding-right: 10px">
        <div style="text-align: center; margin-bottom: 24px">
          <b>TRIPARTITE SERVICE LEVEL AGREEMENT</b><br />
          <span> GRUPA, {{ teamName }} AND {{ clientName }} </span>
        </div>
        <div class="spandiv" style="margin-bottom: 24px">
          This Service Level Agreement (“this Agreement” or “SLA”) is a legally
          binding document by PLATOHUB INC. dba GRUPA., a corporation registered
          under the laws of the State of Delaware and with a registered office
          address at 4320 Stevens Creek Boulevard, Suite 175, San Jose, CA;
          95129 (trading under the brand name “Grupa.io” or “Grupa”) is made
          Between.
        </div>

        <div class="spandiv" style="margin-bottom: 24px">
          {{ teamName }} ("the Consultant", &quot;Co-creator&quot; or "Expert"),
          and {{ clientName }}, a corporation registered under the laws of
          Delaware with registered office address at {Startup.Address} (“the
          Customer”).
        </div>
        <div class="spandiv" style="margin-bottom: 24px">
          By registering and subscribing to the Grupa platform, the Customer and
          Consultant both agree to be bound by the terms of this Agreement under
          the laws of the United States of America (“Elected Country”).
        </div>

        <div class="spandiv" style="margin-bottom: 24px">
          In this Agreement, "Expert(s)" means the applicable Consultant and any
          of its applicable Affiliates, and “You” means the Customer (if
          registering for or using a Service as an individual), or the business
          employing or represented by the registrant (if registering for or
          using the platform as a business) and any of its affiliates. You and
          Consultant mean the "Parties".
        </div>

        <!-- <div class="spandiv" style="margin-bottom: 24px">
          <div v-for="(engr, index) in teamDetails.members" :key="index">
            {{ engr.name }} an individual resident at
            {{
              engr.user_location
                ? engr.user_location
                : engr.address
                ? engr.address
                : ""
            }}
          </div>
        </div> 
          <div class="spandiv" style="margin-bottom: 24px">
          Jointly referred to as the Consultant)
        </div>
        <div class="spandiv" style="margin-bottom: 18px">and</div>
        <div class="spandiv" style="margin-bottom: 18px">
          '{{ project.project_name }}', a corporation registered
          under the laws of and with registered office address at 'Company
          Address', (trading under the brand name '{{
            project.project_name
          }}') you (also referred to as the Client).
        </div>
        <div class="spandiv" style="margin-bottom: 18px">
          By registering and subscribing to the Grupa platform, you both agree
          to be bound by the terms of this Agreement that apply for each country
          for which you register or elect to use a service.
        </div> -->
        <!--  <div class="spandiv" style="margin-bottom: 18px">
          In this Agreement, "{{ teamDetails.team_name }}" means the applicable
          Consultant and any of its applicable Affiliates, and "you" means the
          client (if registering for or using a Service as an individual), or
          the business employing or representing the applicant (if registering
          for or using a service as a business) and any of its affiliates. You
          and Consultant mean the "Parties". Capitalized terms have the meanings
          given to them in this Agreement.
        </div> -->
        <div class="spandiv" style="margin-bottom: 18px"><b>WHEREAS:</b></div>
        <div class="spandiv" style="margin-bottom: 12px">
          A. The Consultant is knowledgeable and skilled in the relevant
          software development.
        </div>
        <div class="spandiv" style="margin-bottom: 12px">
          B. During this engagement, both Parties will encounter, obtain or
          receive information that is considered confidential information (as
          hereinafter defined).
        </div>
        <div class="spandiv" style="margin-bottom: 12px">
          C. The Customer has conceptualized a business idea, which is described
          in detail below;
        </div>
        <div class="spandiv" style="margin-bottom: 28px">
          D. The Customer now engages the Consultant to build and advice on
          certain innovative solutions in the Consultant’s capacity as a
          technology expert service provider.
        </div>
        <div class="spandiv" style="margin-bottom: 18px">
          <b>DEFINITIONS & INTERPRETATIONS</b>
        </div>
        <div style="margin-bottom: 18px">
          <span style="font-weight: bold">"Agreement"</span>
          <span>
            shall mean this agreement, any schedule and appendix attached
            thereto as well as addendum executed pursuant to this
            Agreement</span
          >
        </div>
        <div style="margin-bottom: 18px">
          <span style="font-weight: bold">"Grupa.io"</span>
          <span>
            shall mean the web-based technology platform that connects product
            teams to business owners and through which the Client has initially
            engaged the services of the Consultant;</span
          >
        </div>
        <div style="margin-bottom: 18px">
          <span style="font-weight: bold">"Services"</span>
          <span>
            shall mean the Development of Digital Solutions for the Client as
            more particularly described in the Statement of Work attached to
            this Agreement and provided via the Grupa.io platform.</span
          >
        </div>
        <div style="margin-bottom: 18px">
          <span style="font-weight: bold">"Statement of Work"</span>
          <span style=""
            >shall mean the statement of work, the scope of work, and product
            roadmap agreed between the Parties to form the core of the Services
            to be provided by the Consultant to the Client and detailed in the
            appendix to this Agreement.</span
          >
        </div>
        <div style="margin-bottom: 18px">
          <span style="font-weight: bold">NOW,THEREFORE,</span>
          <span style=""
            >in consideration of the earlier discussions between the Client and
            Consultant, regarding design thinking, product strategy and
            development of product workflow to create iterations of a product,
            and the mutual covenants and promises made by the Parties to this
            Service Level Agreement, the Company and the Client (individually,
            each a "Party" and collectively, the "Parties") covenant and agree
            as follows</span
          >
        </div>
        <div style="font-weight: bold">1. DESCRIPTION OF SERVICES</div>
        <div style="margin-bottom: 18px">
          The Consultant will provide software engineering services for the
          development and engineering of a web and mobile software platform
          (“the Product”) for the Customer by providing product engineering and
          leadership as requested by the Customer.
        </div>

        <table
          style="
            width: 100%;
            border: 1px solid black;
            margin-top: 10px;
            margin-bottom: 20px;
          "
        >
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td style="padding: 10px; background-color: #eee" colspan="2">
              SECTION A: Engagement Expectations
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 30%;
                border: 1px solid #ccc;
              "
            >
              Services
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              Tech development &amp; consulting
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Service Capacity
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Initial Term
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Name of the Product
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Brief Description of Product
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Key Goals
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Reservation Fee
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              $500 for booking fees. For an initial consultation, evaluation,
              ideation, and execution planning.
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Grupa SaaS Fee
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              $0 (Grupa platform is free to use until June 2023)
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Services
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              Company will use commercially reasonable efforts to provide the
              Customer with the services described in the Statement of Work
              (“SOW”) attached as Exhibit A hereto (“Services”), and the
              Customer shall pay Company the Implementation Fee in accordance
              with the terms herein.
            </td>
          </tr>
        </table>
        <br />
        <div style="font-weight: bold">
          2. COMPENSATION (PER EXPERT):
          <span style="text-transform: uppercase">
            {{ grupaCompensation(project.compensation_id) }}</span
          >
        </div>

        <table
          style="
            width: 100%;
            border: 1px solid black;
            margin-top: 10px;
            margin-bottom: 20px;
          "
        >
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Startup Stage
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              Part-time Commitment [15-20hrs per week]
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Cash
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              ${{ project.price.toLocaleString() }}
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              In USD per month
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Equity
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              {{ equity }}%
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              In % over the term of the contract Equity Vesting Schedule: Each
              Equity compensation granted to the Consultant shall vest equally
              every month over an 18-month period with an initial 3-month cliff
              period, subject to the performance and achievement of targets set
              by the Customer in its product roadmap.
            </td>
          </tr>
        </table>
        <br />
        <div style="font-weight: bold">
          3. CONSULTANT: COMPOSITION AND ENGAGEMENT DETAILS
        </div>

        <table
          style="
            width: 100%;
            border: 1px solid black;
            margin-top: 10px;
            margin-bottom: 20px;
          "
        >
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td style="padding: 10px; background-color: #eee" colspan="6">
              Experts
            </td>
          </tr>
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            ></td>
            <th
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              Hours/Week
            </th>

            <th
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              Cash/Month
            </th>
            <th
              style="
                padding: 10px;
                width: 20%;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              Equity
            </th>
            <th
              style="
                padding: 10px;
                width: 20%;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              Subtotal
            </th>
          </tr>

          <tr
            v-for="(engr, index) in teamDetails.members"
            style="border: 1px solid #ccc; padding: 10px"
          >
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              {{ engr.name }}
            </td>
            <th
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              {{ engr.name }}
            </th>
            <th
              style="
                padding: 10px;
                width: 20%;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              {{ project.desired_hours }}
            </th>

            <th
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 1px solid #ccc;
              "
            >
              ${{ project.price ? project.price.toLocaleString() : "" }}
            </th>
            <th
              style="
                padding: 10px;
                width: 20%;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              {{ equity }}%
            </th>

            <th
              style="
                padding: 10px;

                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
             ${{ project.price.toLocaleString() }}
            </th>
          </tr>
        </table>

        <table
          style="
            width: 100%;
            border: 0px solid black;
            margin-top: 20px;
            margin-bottom: 20px;
          "
        >
          <tr>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 0px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 0px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                width: 20%;
                background-color: #fff;
                border: 0px solid #ccc;
              "
            ></td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 20%;
                border: 0px solid #ccc;
                padding-left: 20px;
                text-align: right;
              "
            >
              Subtotal
            </td>
            <td
              style="
                padding: 10px;
                width: 20%;
                background-color: #fff;
                border: 0px solid #ccc;
              "
            ></td>

            <th style="padding: 10px; background-color: #fff">
              ${{ teamSubTotal.toLocaleString() }}.00
            </th>
          </tr>
          <tr>
            <td style="padding: 10px; background-color: #fff; width: 20%"></td>
            <td style="padding: 10px; width: 20%"></td>
            <td style="padding: 10px; width: 20%; background-color: #fff"></td>

            <td
              style="padding: 10px; width: 20%; padding-left: 20p;text-align: right;x"
            >
              Discount
            </td>
            <td style="padding: 10px; width: 20%"></td>

            <th style="padding: 10px; background-color: #fff">$0.00</th>
          </tr>

          <tr>
            <td style="padding: 10px; background-color: #fff; width: 20%"></td>
            <td style="padding: 10px; width: 20%"></td>
            <td style="padding: 10px; width: 20%; background-color: #fff"></td>

            <td
              style="
                padding: 10px;
                width: 20%;
                padding-left: 20px;
                text-align: right;
              "
            >
              Tax
            </td>
            <td style="padding: 10px; width: 20%"></td>

            <th style="padding: 10px; background-color: #fff">$562.50</th>
          </tr>

          <tr>
            <td style="padding: 10px; background-color: #fff; width: 20%"></td>
            <td style="padding: 10px; width: 20%"></td>
            <td style="padding: 10px; width: 20%; background-color: #fff"></td>

            <th
              style="
                padding: 10px;
                width: 20%;
                padding-left: 20px;
                text-align: right;
              "
            >
              Total
            </th>
            <td style="padding: 10px; width: 20%"></td>

            <th style="padding: 10px; background-color: #fff">
              ${{ (parseInt(teamSubTotal) + 562.5).toLocaleString() }}.00
            </th>
          </tr>
        </table>

        <div style="font-weight: bold">4. SERVICE DURATION</div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          1. The Consultant shall provide 18 months of engineering service with
          initial 3 months as a trial period with a cliff as outlined below and
          in accordance with the specifications attached hereto as Statement of
          Work (the “SOW”) agreed between the Parties.
        </div>
        <div style="margin-bottom: 18px; margin-left: 20px">
          2. The project execution will commence immediately upon execution of
          this SLA and payment of initial Consideration as stated below
        </div>

        <div style="font-weight: bold; margin-top: 20px">5. PAYMENT TERMS</div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          5.1 The Consultant and the Customer understand that Grupa shall be
          entitled to {{project.percentage_cut}}% of any Cash and or Equity compensation given to the
          Consultant by the Customer.
        </div>
        <div style="margin-bottom: 20px; margin-left: 20px">
          5.2 The Customer shall pay Grupa.io a total monthly rate of USD {{ project.price.toLocaleString() }}
          (VAT exclusive) and {{equity}}% equity vested over 18 months, subject to the
          vesting schedule in clause 3 above and the compensation breakdown
          below in this clause 5.
        </div>

        <div style="margin-bottom: 20px; margin-left: 20px">
          5.3 The Customer shall ensure prompt payment of the initial
          Consideration within 5 (five) working days of execution of this SLA,
          through the Grupa.io platform.
        </div>
        <div style="margin-bottom: 20px; margin-left: 20px">
          5.4 Subsequently, the payment due to the Consultant shall be released
          upon completion of weekly sprint and on the Customer’s approval of
          such sprint completed by the Consultant.
        </div>
        <div style="margin-bottom: 20px; margin-left: 20px">
          5.5 The payment shall be remitted to the Experts within 5 working days
          after confirmation of payment by the Customer, taking into
          consideration any payment processing periods.
        </div>
        <div style="margin-bottom: 20px; margin-left: 20px">
          5.6 Disbursements and payouts shall be made bi-weekly based on the
          weekly sprint breakdown of payment outlined below.
        </div>
        <div style="margin-bottom: 20px; margin-left: 20px">
          5.7 The table below shows the payment schedule per iteration for the
          Consultant(s):
        </div>

        <!-- <div style=" margin-bottom:18px;"> -->
        <!--  <table class="table" width="100%" id="table" style="font-size: 12px">
          <thead class="thead-light">
            <tr class="head-text">
              <td scope="col">Id</td>
              <td scope="col">Iterations</td>
              <td scope="col">When due payable</td>
              <td scope="col">Amount/%</td>
              <td scope="col" v-if="!devUser">Amount ($)</td>
              <td scope="col" v-else>Team Cut($)</td>
            </tr>
          </thead>
          <tbody v-for="(itr, index) in projectDoc.iterations" :key="index">
            <tr>
              <td>{{ itr.id }}</td>
              <td>{{ itr.name }}</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr
              v-for="sprint in projectDoc.sprints"
              v-if="sprint.iteration_id == itr.id && devUser"
              :key="sprint.id"
            >
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>Sprint {{ sprint.sprint }}</td>
              <td>
                {{ (100 / project.iteration_cycle).toFixed(2) }}%
              </td>
              <td v-if="!devUser">
                {{
                  (
                    project.price /
                    project.iteration_cycle
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </td>
              <td v-else>
                {{
                  (
                    (project.price / 4) *
                    project.no_of_engineers *
                    teamPercent
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>Total Payment for iteration {{ index + 1 }}</td>
              <td>&nbsp;</td>
              <td v-if="!devUser">
                {{
                  (
                    project.price *
                    project.no_of_engineers
                  ).toLocaleString()
                }}
              </td>
              <td v-else>
                {{
                  (
                    project.price *
                    project.no_of_engineers *
                    teamPercent
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </td>
            </tr>
          </tbody>
        </table> -->
        <!-- Payment Table Here... -->
        <!-- </div> -->

        <table
          style="
            width: 100%;
            border: 1px solid black;
            margin-top: 30px;
            margin-bottom: 20px;
          "
        >
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              ITERATION
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              WEEKLY SPRINT
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              STARTUP PAYS PER EXPERT
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              GRUPA TAKE RATE (IN %)
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              EXPERT TAKEHOME
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              Month 1...18
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              SPRINT 1
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              ${{ project.price.toLocaleString() }}
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
            {{project.percentage_cut}}% 
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              ${{ (project.price/4).toLocaleString() }}
            </td>
          </tr>
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              SPRINT 2
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              ${{ project.price.toLocaleString() }}
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
            {{project.percentage_cut}}% 
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              ${{( project.price/4).toLocaleString() }}
            </td>
          </tr>

          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              SPRINT 3
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
               ${{ project.price.toLocaleString() }}
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
            {{project.percentage_cut}}% 
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
               ${{ (project.price/4).toLocaleString() }}
            </td>
          </tr>
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            ></td>
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              SPRINT 4
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
               ${{ project.price.toLocaleString() }}
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
            {{project.percentage_cut}}% 
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
              "
            >
              ${{ (project.price/4).toLocaleString() }}
            </td>
          </tr>
        </table>

        <div style="margin-bottom: 20px; margin-left: 20px">
          5.8 The Customer shall ensure prompt provision of dependencies as
          requested before the agreed due date based on the Product roadmap in
          the Appendix below. Any delay in the provision of resources/items or
          dependencies may lead to missed sprint due dates and will incur
          charges for the extension of the sprint timeline based on the
          calculations in this clause.
        </div>

        <div style="font-weight: bold; margin-top: 30px">
          6. OBLIGATIONS OF THE PARTIES
        </div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          6.1 OBLIGATIONS OF THE CONSULTANT
        </div>
        <div style="margin-bottom: 10px; margin-left: 40px">
          6.1.1 Develop, create and or advice on products or software in line
          with the contents of the Statement of Work and provide weekly updates
          to the Customer.
        </div>
        <div style="margin-bottom: 10px; margin-left: 40px">
          6.1.2 Ensure effective communication to the Customer regarding the
          Product and any queries or questions that may arise.
        </div>

        <div style="margin-bottom: 10px; margin-left: 40px">
          6.1.3 Be required to respond timely to any questions, issues, queries,
          and or concerns from the Customer regarding documentation and
          requirements in accordance with Clause 7 (Escalation Process) below.
        </div>

        <div style="margin-bottom: 10px; margin-left: 40px">
          6.1.5 Complete the development of the Software according to the
          milestones described on the form attached hereto as Exhibit A.
        </div>
        <div style="margin-bottom: 10px; margin-left: 40px">
          6.1.6 The Consultant affirms as follows:
        </div>
        <div style="margin-bottom: 10px; margin-left: 60px">
          a. that undertaking these Services or any service on the Grupa
          platform have no conflict with any of their existing jobs,
        </div>

        <div style="margin-bottom: 10px; margin-left: 60px">
          b. that, with the exclusion of open sourced, paid, subscription tools
          or equipment, they will not use any resources, equipment or licenses
          owned by any third party to carry out this Service, and
        </div>

        <div style="margin-bottom: 10px; margin-left: 60px">
          c. that taking up the Service will not cause them to reveal any
          Intellectual Property belonging to any third party or breach any
          employment or service agreement to which they are a party to or
          currently bound to.
        </div>

        <div style="margin-bottom: 8px; margin-left: 20px">
          6.2 OBLIGATIONS OF THE CUSTOMER
        </div>

        <div style="margin-bottom: 10px; margin-left: 40px">
          6.2.1 immediately upon execution of this Agreement, provide all
          relevant information including but not limited to the Statement of
          Work and other requirements.
        </div>
        <div style="margin-bottom: 10px; margin-left: 40px">
          6.2.2 Within 24 hours of communication from the team required to
          respond to questions, issues, queries, and or concerns regarding
          documentation and requirements.
        </div>
        <div style="margin-bottom: 10px; margin-left: 40px">
          6.2.3 Promptly and upon completion of the SOW, pay the full and final
          consideration based on the Grupa service plan selected.
        </div>

        <div style="font-weight: bold; margin-top: 30px">
          6. OBLIGATIONS OF THE PARTIES
        </div>

        <div style="margin-bottom: 10px; margin-left: 20px">
          Each Party hereby acknowledges that this Agreement will require
          significant interaction between the Parties on a regular basis
          throughout the Term of the Agreement. Each Party further agrees that
          each Party shall designate an “Escalation Contact” creating a single
          line of communication for the purpose of resolving specific problems
          or issues. Each Party will notify the other Party in a timely manner
          of the name and contact information of their Escalation Contact. In
          order to facilitate an efficient and effective process to resolve
          matters that impact either Party’s operation under this Agreement, the
          Parties hereby institute the Escalation Process described herein. The
          Escalation Contact from the Consultant will notify the Escalation
          Contact from the Customer when any feedback concerning the
          documentation and requirements in relation to the critical path of the
          project has been initially requested and has not been responded to
          within 24 hours of the request. Upon notification from the Escalation
          Contact from Consultant to the Escalation Contact from the Customer,
          the Customer shall be required to respond within twenty-four (24)
          hours of the request.
        </div>

        <div style="margin-bottom: 10px; margin-left: 20px">
          Where no response is received by the contact escalating 24 hours after
          escalation, such contact shall immediately inform Grupa to enable
          Grupa take the right actions.
        </div>

        <div style="font-weight: bold">8. PRODUCT INTELLECTUAL PROPERTY</div>
        <div style="margin-bottom: 18px; margin-left: 20px">
          Any copyrightable works, ideas, discoveries, inventions, patents,
          products, or other information (collectively the “Work Product”)
          developed in whole or in part by Consultant in connection with this
          Agreement shall be solely and irrevocably the exclusive property of
          the Customer, save where the Customer in written format permits or
          grants ownership to Consultant for use. The Consultant irrevocably
          assigns all rights in such Work Product to the Customer as further
          described in Appendix B below.
        </div>
        <div style="font-weight: bold">
          9. CHANGE IN STATEMENT OF WORK AND/OR SCOPE OF WORK
        </div>
        <div style="margin-bottom: 18px; margin-left: 20px">
          The Customer may request change(s) to the Statement of Work attached
          to this agreement after this Agreement has been executed. In the event
          that a change is proposed by the Customer, the Customer shall make
          such a request on the Grupa.io platform detailing the changes to be
          made and follow the process as stated on the Grupa.io platform. The
          Customer understands that there shall be additional fees and timing
          required for the changes to be effected, subject to the mutual
          agreement of both parties. The Customer shall accept such additional
          fees and timing to ensure completion and delivery of the Product.
        </div>
        <div style="font-weight: bold">10. CONFIDENTIALITY</div>
        <div style="margin-bottom: 18px; margin-left: 20px">
          The Consultant undertakes not to disclose to any third party the
          nature of work of the Customer, including the business of the
          Customer, details regarding the Product and Statement of Work,
          including, without limitation any information regarding the Product’s
          code, the specifications, the Work Product or the Customer’s customers
          (the “Confidential Information”), (ii) make copies of any Confidential
          Information or any content based on the concepts contained within the
          Confidential Information for personal use or for distribution unless
          so permitted by the Customer, or (iii) use Confidential Information
          other than solely for the benefit of the Customer.
          <br />
          <br />

          “Confidential Information” means all information or data disclosed
          (whether in writing, orally or by any other means) to one party by the
          other party or by a third party on behalf of the other party and shall
          include but not be limited to;
        </div>
        <div style="margin-bottom: 18px">
          <div style="margin-bottom: 8px; margin-left: 20px">
            (a) Information relating to the organizational structure, marketing
            strategy, and product roadmap of the Parties and any other
            information relating to the business operations, processes, plans,
            intentions, product information, know-how, design rights, trade
            secrets, software, market opportunities, customers and affairs of
            the Parties;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (b) Technical information to any products or services supplied by
            the Parties;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (c) Information relating to the officers, directors, or employees of
            the Parties including their terms and conditions of employment or
            engagement;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (d) The information which is disclosed orally and identified as
            confidential at the time of disclosure;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (e) The existence and content of this Agreement and matters
            incidental thereto;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (f) Any information ascertainable by the inspection of accounts, and
            other records;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (g) Financial, legal, and any other information provided in due
            diligence checklists to be provided after execution hereof;
          </div>
          <div style="margin-bottom: 8px; margin-left: 20px">
            (h) Any information relating to that Party’s business, operations,
            plans, intentions, market opportunities, customers, and business
            affairs; and
          </div>

          <div style="margin-bottom: 28px; margin-left: 20px">
            (i) Any Intellectual Property.
          </div>
        </div>

        <div style="font-weight: bold">11. WARRANTY</div>
        <div style="margin-left: 22px; margin-bottom: 18px">
          11.1 The Consultant shall provide its services and meet its
          obligations under this Agreement in a timely and workmanlike manner,
          using knowledge and recommendations for performing the services which
          meet generally acceptable standards and will provide a standard of
          care equal to, or superior to, care used by service providers similar
          to any professional Customer on similar projects.
        </div>
        <div style="margin-left: 22px; margin-bottom: 18px">
          11.2 The Consultant warrants that the Services provided by the
          Consultant shall not violate any intellectual property of any other
          Customer or party.
        </div>
        <div style="font-weight: bold; margin-top: 20px">12. REFUNDS</div>
        <div style="margin-left: 22px; margin-bottom: 18px">
          12.1 Where the Customer is dissatisfied with the design-thinking or
          product roadmapping session held with the Consultant, it shall be
          entitled to a refund of some or all of the Reservation fees paid to
          the Company for the Services. This clause shall be effective
          immediately upon engagement of the Consultant on the Grupa.io platform
          and payment of Reservation fees.
        </div>
        <div style="margin-left: 22px; margin-bottom: 18px">
          12.2 Upon commencement of Services requested by the Customer, a
          non-repayment clause shall be effective immediately upon payment of
          agreed Consideration and the commencement of work by the Consultant.
        </div>

        <div style="font-weight: bold; margin-top: 40px">13. TERMINATION</div>
        <div style="margin-left: 22px; margin-bottom: 18px">
          13.1 The Agreement will commence immediately upon execution of this
          Agreement by the last signee and shall terminate upon completion,
          fulfillment, and delivery by the Consultant of the Services described
          here.
        </div>
        <div style="margin-left: 22px; margin-bottom: 18px">
          13.2 Any Party may terminate this Agreement in the event of an
          occurrence of a default or at will, upon the non-defaulting Party
          giving a 4-week notice period.
        </div>

        <div style="margin-left: 22px; margin-bottom: 8px">
          13.3 An event of default shall be:
        </div>

        <div style="margin-left: 42px; margin-bottom: 18px">
          <div style="margin-left: 15px">
            13.3.1 Non-payment of Consideration by Customer as at and when due;
          </div>
          <div style="margin-left: 15px">
            13.3.2 Failure by the Client to deliver such information or
            materials required for the Product;
          </div>
          <div style="margin-left: 15px">
            13.3.3 Consultant's failure to perform the Product as contained in
            the Statement of Work;
          </div>
          <div style="margin-left: 15px">
            13.3.4 Either Party not fulfilling its Duties or Obligations as
            stated in this Agreement.
          </div>
        </div>
        <div style="font-weight: bold; margin-top: 30px">14. NO WAIVER</div>
        <div style="margin-bottom: 18px">
          No indulgence, which any Party may grant to the other Party, shall
          constitute a waiver of any of the Duties or Obligations of the
          grantor.
        </div>
        <div style="font-weight: bold; margin-top: 30px">15. FORCE MAJEURE</div>

        <div style="margin-bottom: 18px; margin-left: 22px">
          15.1 If the performance of this Agreement or any obligation under this
          Agreement is prevented, restricted, or interfered with by causes
          beyond either Party's reasonable control ("Force Majeure"), and if a
          Party is unable to carry out its obligations and give the other Party
          prompt written notice of such an event through the Grupa.io platform,
          then the obligations of the Party invoking this provision shall be
          suspended to the extent necessary by such an event.
        </div>
        <div style="margin-bottom: 18px; margin-left: 22px">
          15.2 The term Force Majeure shall include, without limitation, acts of
          God, fire, explosion, vandalism, storm or other similar occurrence,
          orders or acts of military or civil authority or by national
          emergencies, insurrections, riots, or wars, or strikes, lock-outs,
          work stoppages, or other labor disputes. The excused party shall use
          reasonable efforts under the circumstances to avoid or remove such
          causes of non-performance and shall proceed to perform with reasonable
          dispatch whenever such causes are removed or ceased. An act or
          omission shall be deemed within the reasonable control of a party if
          committed, omitted, or caused by such party, or its employees,
          officers, agents, or affiliates.
        </div>
        <div style="font-weight: bold">16. ENTIRE AGREEMENT</div>
        <div style="margin-bottom: 18px">
          This Agreement contains the entire agreement of the Parties and there
          are no other promises or conditions in any other agreement whether
          oral or written concerning the subject matter of this Agreement, other
          than such as been consented and agreed upon the Grupa.io platform.
          Notwithstanding, this Agreement supersedes any prior written or oral
          agreements between the Parties.
        </div>
        <div style="font-weight: bold; margin-top: 30px">17. SEVERABILITY</div>
        <div style="margin-bottom: 18px">
          If any provision of this Agreement will be held to be invalid or
          unenforceable for any reason, the remaining provisions will continue
          to be valid and enforceable. If a court finds that any provision of
          this Agreement is invalid or unenforceable, but that by limiting such
          provision it would become valid and enforceable, then such provision
          will be deemed to be written, construed and enforced as so limited.
        </div>
        <div style="font-weight: bold; margin-top: 30px">18. AMENDMENT</div>
        <div style="margin-bottom: 18px">
          This Contract may be modified or amended in writing by mutual
          agreement between the Parties on the Grupa.io platform if the writing
          is consented to by the party obligated under the amendment and
          approved on the Grupa.io platform.
        </div>
        <div style="font-weight: bold; margin-top: 30px">19. GOVERNING LAW</div>
        <div style="margin-bottom: 18px">
          This Agreement and the interpretation of its terms shall be governed
          by and construed in accordance with the laws of the state of Delaware
          and subject to the exclusive jurisdiction of the courts located in
          Delaware, USA.
        </div>
        <div style="font-weight: bold; margin-top: 30px">
          20. DISPUTE RESOLUTION
        </div>
        <div style="margin-bottom: 18px">
          The Parties shall endeavor to settle amicably any dispute, controversy
          or claim arising whether directly or indirectly from this Agreement by
          raising support tickets on the Grupa.io platform. If the dispute,
          difference, or claim is not settled amicably within 60 days of raising
          a support ticket or escalating such matter to Grupa, the Agreement
          shall terminate and the Parties shall be referred to mediation or the
          Courts of Chancery, Delaware.
        </div>
        <div style="font-weight: bold; margin-top: 30px">
          21. ANTI-BRIBERY & CORRUPTION LAWS
        </div>
        <div style="margin-left: 15px">
          21.1 The Parties confirm that they have not and no one working on
          their behalf, including any employee, agent, or consultant, has
          requested, received or been offered, made or promised any incentive to
          any person with the intent to influence or induce such a person to do
          or cease from carrying out an act or to cause such person to grant any
          advantage whatsoever in connection with the agreement between the
          Parties.
        </div>
        <div style="margin-left: 15px; margin-bottom: 8px">
          21.2 The Parties further confirm that if they or anyone working for or
          on their behalf should learn of information regarding any such payment
          or offer, or any other violation of the Anti-Corruption Laws, in
          connection with any businesses or contracts between Parties, they will
          immediately advise Grupa of his or her knowledge or suspicion by
          emailing founders@grupa.io .
        </div>
        <div style="margin-left: 15px; margin-bottom: 8px">
          21.3 A breach of the provisions of this clause by either Party will be
          grounds for the termination of this Agreement.
        </div>
        <div style="font-weight: bold; margin-top: 30px">
          22. INDEMNIFICATION
        </div>
        <div style="margin-left: 15px; margin-bottom: 8px">
          22.1 The Consultant hereby indemnifies and holds harmless the Customer
          and Grupa from any and all claims, demands, suits, actions,
          proceedings, loss, cost, interest, award penalties, fines, damages, or
          expenses of any kind and description, including attorneys’ fees and/or
          litigation expenses, any claim of a third party, arising out of or
          occurring in connection with the Consultant’s negligence, willful
          misconduct, or breach of this Agreement.
        </div>
        <div style="margin-left: 15px; margin-bottom: 8px">
          22.2 The Customer hereby indemnifies and holds harmless Consultant and
          Grupa from any and all claims, demands, suits, actions, proceedings,
          loss, cost, interest, award penalties, fines, damages, or expenses of
          any kind and description, including attorneys’ fees and/or litigation
          expenses, any claim of a third party, arising out of or occurring in
          connection with the Customer’s negligence, willful misconduct, or
          breach of this Agreement.
        </div>
        <div style="font-weight: bold; margin-top: 30px">
          23. INDEPENDENT CONTRACTOR
        </div>
        <div style="margin-left: 15px; margin-bottom: 18px">
          23.1 The Consultant is an independent private contractor, and neither
          Consultant nor its employees, agents, or other representatives shall
          be considered employees or agents of the Client or of Grupa.io or any
          of its affiliates.
        </div>
        <div style="margin-left: 15px; margin-bottom: 18px">
          23.2 It is understood and agreed by the parties that nothing contained
          in this Agreement shall be construed to create a joint venture,
          partnership, association, or other affiliation or relationship between
          the Parties, it being specifically agreed that their relationship is
          and shall remain that of independent parties to a contractual
          relationship as set forth in this Agreement.
        </div>
        <div style="margin-left: 15px; margin-bottom: 30px">
          23.3 The Consultant agrees to perform and discharge all obligations as
          an independent contractor under any and all laws, whether existing or
          in the future, in any way pertaining to the engagement of Product
          under this Agreement.
        </div>
        <div style="margin-bottom: 18px">
          By accepting this Agreement, each Party agrees and understand that you
          have read and understood this Agreement and undertake to comply and do
          all that is written in it.
        </div>

        <div style="font-weight: bold; margin-bottom: 18px; margin-top: 30px">
          SIGNATURE FOR THE CONSULTANT {EXPERT(s)}
        </div>

        <table
          style="
            width: 100%;
            border: 1px solid black;
            margin-top: 32px;
            margin-bottom: 20px;
          "
        >
          <tr style="border: 1px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width:30%
                border: 1px solid #ccc;
              "
            >
              Name
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;

                border: 1px solid #ccc;
              "
            >
              Address
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
                width: 30%;
              "
            >
              Date & Sign
            </td>
          </tr>

          <tr
            style="border: 1px solid #ccc; padding: 10px"
            v-for="engr in teamDetails.members"
          >
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 30%;
                border: 1px solid #ccc;
              "
            >
              {{ engr.name }}
            </td>
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 30%;
                border: 1px solid #ccc;
              "
            >
              {{ engr.address }}
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 30%;
                border: 1px solid #ccc;
              "
            ></td>
          </tr>
        </table>

        <table
          style="
            width: 100%;
            border: 1px solid #ccc;
            margin-top: 30px;
            margin-bottom: 20px;
          "
        >
          <tr style="border: 1px solid #ccc; padding: 10px">
            <th
              style="
                padding: 10px;
                background-color: #fff;
                width: 50%;
                border: 1px solid #ccc;
              "
            >
              THE CUSTOMER
            </th>

            <th
              style="
                padding: 10px;
                background-color: #fff;
                border: 1px solid #ccc;
                width: 50%;
              "
            >
              GRUPA
            </th>
          </tr>

          <tr style="border: 0px solid #ccc; padding: 10px">
            <td
              style="
                padding: 10px;
                background-color: #fff;
                width: 50%;
                border: 0px solid #ccc;
                vertical-align: top;
              "
            >
              NAME: {{ clientName }}
            </td>

            <td
              style="
                padding: 10px;
                background-color: #fff;
                border-left: 1px solid #ccc;
                width: 50%;
                vertical-align: top;
              "
            >
              NAME: Damilola Thompson, Co-founder, COO, Platohub Inc, dba
              <b>Grupa</b>
            </td>
          </tr>

          <tr style="border: 0px solid #ccc; padding: 10px">
            <td style="padding: 10px; vertical-align: top">
              ADDRESS: {{ project.organization_address }}
            </td>

            <td style="padding: 10px; border-left: 1px solid #ccc">
              ADDRESS: 4320 STEVENS CREEK BLVD, SAN JOSE
            </td>
          </tr>
          <tr style="border: 0px solid #ccc; padding: 10px">
            <td style="padding: 10px; vertical-align: top">
              DATE: {{ new Date().toDateString() }}
            </td>

            <td
              style="
                padding: 10px;
                border-left: 1px solid #ccc;
                padding-bottom: 50px;
                vertical-align: top;
              "
            >
              DATE: {{ new Date().toDateString() }}
            </td>
          </tr>

          <tr style="border: 0px solid #ccc; padding: 10px">
            <td style="padding: 10px; vertical-align: top; vertical-align: top">
              SIGN:
            </td>

            <td
              style="
                padding: 10px;
                border-left: 1px solid #ccc;
                padding-bottom: 50px;
                vertical-align: top;
              "
            >
              SIGN:
            </td>
          </tr>
        </table>

        <div style="font-weight: bold; margin-top: 40px">
          APPENDIX: STATEMENT OF WORK
        </div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          a. PRODUCT ROADMAP: We hereby adopt as reference the outline of the
          product roadmap developed on the Grupa platform as the statement of
          work (i.e the Service to be done) as agreed by both parties.
        </div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          b. Product Management: To manage the development of the Sympler V1
          platform
        </div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          c. Execution Management Tracking System: To guarantee delivery and
          compliance of both parties, the Customer and the Consultant commit to
          tracking and managing the product execution and statement of work on
          the Grupa platform.
        </div>

        <div style="font-weight: bold; margin-top: 40px">
          APPENDIX B: ASSIGNMENT OF INTELLECTUAL PROPERTY
        </div>
        <div style="margin-bottom: 10px; margin-left: 20px">
          Subject to receipt of full payment from Customer for work done,
          Customer shall own, and Consultant hereby irrevocably transfers to
          Customer, all rights, titles, and interests in and to all Services
          created by Consultant for Customer, as set forth in the applicable
          Statement of Work, and any intellectual property rights connected
          therewith, including, but not limited to all ideas, inventions,
          discoveries, know-how, computer programs, improvements, methods,
          developments, and other creative works and works of authorship,
          whether or not patentable or copyrightable, conceived or made or
          reduced to practice (whether solely or with the participation of
          others) by Consultant. Consultant shall not retain the right to reuse
          or incorporate, without the written consent of Customer, its
          technology, Pre-existing works, work product, and intellectual
          property provided in the Services or the development of the Services.
          If any Services falls within the definition of “work made for hire” in
          the U. S. Copyright Act, 17 U.S.C. §101 et seq., then for purposes of
          this Agreement such Services shall be deemed to be a “work made for
          hire,” the copyright of which shall be owned solely and exclusively by
          Customer. If Consultant would be deemed to retain by operation of law
          or otherwise any intellectual property rights in any such Services,
          then Consultant hereby assigns and transfers all such retained
          intellectual property rights to Customer and its successors. where the
          Consultant has retained any rights in the Services for themselves, or
          in the event that the Consultant is not available to execute any such
          documents to this effect, when required, the Consultant irrevocably
          appoints the Company as its attorney-in-fact to execute all such
          documents on its behalf.
        </div>
        <div style="margin-bottom: 8px; margin-left: 20px">
          As used in this Agreement, the term “Pre-Existing Works” shall mean
          all the Applications developed by the Customer or its associates,
          copyrights, patents, trade secrets, or other intellectual property
          rights owned by or licensed by the Customer prior to the date of this
          Agreement. Ownership of the Pre-Existing Works and work products shall
          remain with the Customer. Without Customer’s prior written permission,
          Consultant shall not itself, or through any other party use the
          Services to provide any service, or “service bureau” services to third
          parties; or remove any copyright notices from any Services.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { grupaCompensation } from "@/utils/grupaVariables.js";

export default {
  props: ["teamDetails", "projectDoc", "devUser", "project", "equity"],
  data: () => ({
    companyAddress: `5997 Roxie Terrace, Fremont, CA, 94555`,
    grupaCompensation: grupaCompensation,
  }),
  mounted() {
    console.log("equity", this.equity);
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject", "setProjectPrice"]),
    teamCut() {
      let result;
      if (this.project.experience == "shared") {
        result = 0.75;
      } else if (this.project.experience == "flexible") {
        result = 0.7;
      } else if (this.project.experience == "dedicated") {
        result = 0.65;
      } else if (this.project.experience == "colocated") {
        result = 0.6;
      }
      return result;
    },
  },
  computed: {
    clientName() {
      return this.project.hasOwnProperty("startup")
        ? this.project.startup.name
        : "";
    },
    teamName() {
      return Object.keys(this.teamDetails.members).length == 1
        ? this.teamDetails.members[0].name
        : this.teamDetails.team_name;
    },

    teamSubTotal() {
      return Object.keys(this.teamDetails.members).length * this.project.price;
    },
    teamPercent() {
      const percentage_cut = this.project.percentage_cut;
      if (percentage_cut) {
        return (100 - percentage_cut) / 100;
      }
      return 0.8;
    },
  },
};
</script>
<style scoped>
.div_border {
  border: solid 1px;
  border-radius: 5px;
}
/* div {
  page-break-inside: avoid;
  page-break-after: always;
} */
</style>
